<template>
  <div class="pz">
    <el-dialog :title="title" :visible.sync="dialogVisible2" append-to-body width="70%" :before-close="handleClose">
      <el-form style="margin-left: 20px;" :model="searchForm" label-position="right" label-width="80px" inline
        ref="form">
        <el-form-item label="">
          <el-input style="width: 250px" v-model="searchForm.carNumber" placeholder="请输入车牌号">
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <!-- <el-input style="width: 250px" v-model="searchForm.companyName" placeholder="请输入主体名称">
          </el-input> -->
          <el-select v-model="searchForm.isAllocation" placeholder="分配状态" :disabled="type">
            <el-option v-for="item in isAllocationList" :key="item.id" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input style="width: 250px" v-model="searchForm.carBrand" placeholder="请输入车辆品牌">
          </el-input>
          <!-- <el-select v-model="searchForm.carBrand" placeholder="车辆品牌" :disabled="type">
            <el-option v-for="item in jobTypeList" :key="item.id" :label="item.label" :value="item.id">
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="">
          <!-- <el-input style="width: 250px" v-model="searchForm.companyName" placeholder="请输入主体名称">
                          </el-input> -->
          <!-- <el-select v-model="searchForm.manufacturer" placeholder="厂商" :disabled="type">
            <el-option v-for="item in carTypeList" :key="item.id" :label="item.label" :value="item.id">
            </el-option>
          </el-select> -->
          <el-input style="width: 250px" v-model="searchForm.manufacturer" placeholder="厂商">
          </el-input>
        </el-form-item>

        <div>
          <el-button class="query-btn" type="primary" @click="search">查询</el-button>
          <el-button class="query-btn" type="primary" @click="clear">清空</el-button>

        </div>
      </el-form>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="item.name" :name="item.value" v-for="(item,index) in tabs" :key="index">

          <table-page :data="tableData" :columns="columns" :currentPage="query.current" :pageSize="query.size"
            :total="total" :loading="loading" :border="true" @sizeChange="handleSizeChange"
            @currentChange="handleCurrentChange">
            <template slot="operation" slot-scope="scope">
              <el-button  v-if="scope.row.isAllocation!=1" type="text" size="small" @click="distribution(scope.row)">配置</el-button>
              <el-button v-if="scope.row.isAllocation==1" type="text" size="small"
                @click="NoDistribution(scope.row)">取消配置</el-button>
              <!-- <el-button type="text" size="small" @click="detailsClick(scope.row)">详情</el-button>
              <el-button type="text" size="small" @click="Edit(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="deleteClick(scope.row)">删除</el-button> -->
            </template>
          </table-page>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>

  import TablePage from "@/components/table/table-page";
  import { selectAgriculturalList, insertCompanyAgricultural, deleteCompanyAgricultural } from '@/api/njgl'
  import { carType } from '@/api/njgl'
  import { njglDetailColumn, njDetailColumn } from '../../tableComlumns.js'
  export default {
    data() {
      return {
        companyAuthId: '',
        columns: njglDetailColumn,
        activeName: '1',
        dialogVisible2: true,
        loading: false,
        title: '分配',
        type: false,
        total: 0,
        form: [],

        tableData: [],
        carTypeList: [],
        jobTypeList: [],
        query: {
          current: 1,
          size: 10,
        },
        isAllocationList: [
          {
            label: '已分配',
            id: '1'
          },
          {
            label: '未分配',
            id: '0'
          },
        ],
        searchForm: {
          carNumber: '',
          isAllocation: '0',
          carBrand: '',

        },
        tabs: [
          {
            name: '农机',
            value: '1',

          }, {
            name: '机具',
            value: '2',
          }
        ],
        tabsList: [
          {
            name: '农机',
            value: 1,
          },
          {
            name: '机具',
            value: 2,
          },

        ],
      };
    },
    components: {
      TablePage,
    },
    mounted() {
      this.getCarTypeList()

    },
    watch: {
      activeName(newVal) {
        switch (newVal) {
          case '1':
            this.columns = njglDetailColumn;
            break;
          case '2':
            this.columns = njDetailColumn;
            break;
          default:
            this.columns = []; // 或者根据需要设置默认值
            console.warn(`Unexpected activeIndex: ${newVal}`);
            break;
        }
      }
    },

    methods: {
      //父组件传递的值
      initData(activeIndex, companyAuthId) {
        this.companyAuthId = companyAuthId
        const matchedTab = this.tabsList.find(tab => tab.value == activeIndex).name;
        console.log(matchedTab, 'matchedTab')
        this.title = '分配' + matchedTab
        this.selectAgriculturalList()

      },
      // 车辆类型的列表
      async getCarTypeList() {
        let res = await carType('car_type')
        if (res.code == 0) {
          this.carTypeList = res.data
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
      },

      // 切换选项
      handleClick(tab, event) {
        // console.log(tab, event);
        // console.log(tab.paneName, 'paneName')
        this.activeName = tab.paneName
        this.selectAgriculturalList()
        this.query.current = 1,
          this.query.size = 10
      },
      // 列表数据
      async selectAgriculturalList() {
        let params = {
          status: this.activeName,
          // current: 1,
          // size: 10,
          ...this.query,
          // companyAuthId:this.companyAuthId,
          isAllocation: this.searchForm.isAllocation,
        }
        let res = await selectAgriculturalList(params)
        if (res.code == 0) {
          this.total = Number(res.data.total)
          this.tableData = res.data.records
          console.log(this.tableData, 'this.tableData ')


        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
      },
      // 关闭弹窗
      handleClose() {
        this.$emit("closeDialog2", false);
      },
      // 分配
      async distribution(obj) {
        this.$confirm(' 是否继续分配?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            let params = {
              bindIdentityId: this.companyAuthId,
              type: this.activeName,
              [this.activeName == 1 ? 'machineryId' : 'implementId']: obj.id
            };
            let res = await insertCompanyAgricultural(params)
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '分配成功!'
              });
              this.selectAgriculturalList()
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }

          } catch (error) {
            this.$message({
              type: 'error',
              message: '分配失败，请重试'
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消分配'
          });
        });
      },
      //查询
      async search() {
        let params = {
          status: this.activeName,
          // current: 1,
          // size: 10,
          // isAllocation: '0',
          ...this.query,
          ...this.searchForm,
          // companyAuthId: this.companyAuthId,
        }
        let res = await selectAgriculturalList(params)
        if (res.code == 0) {
          this.total = Number(res.data.total)
          this.tableData = res.data.records
          console.log(this.tableData, 'this.tableData ')
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
      },
      //清空
      async clear() {
        let searchForm = {
          carNumber: '',
          isAllocationList: '',
          carBrand: '',

        }
        this.searchForm = searchForm
        let params = {
          status: this.activeName,
          current: 1,
          size: 10,
          // companyAuthId: this.companyAuthId,

        }
        let res = await selectAgriculturalList(params)
        if (res.code == 0) {
          this.total = Number(res.data.total)
          this.tableData = res.data.records
          console.log(this.tableData, 'this.tableData ')
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
      },
      //取消配置
      NoDistribution(obj) {
        this.$confirm(' 是否取消分配?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            let params = {
              companyAuthId: this.companyAuthId,
              type: this.activeName,
              id: obj.id
            };
            let res = await deleteCompanyAgricultural(params)
            if (res.code == 0) {
              this.selectAgriculturalList()
              this.$message({
                type: 'success',
                message: res.msg
              });
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              });
            }

          } catch (error) {
            this.$message({
              type: 'error',
              message: '取消失败，请重试'
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '操作已取消'
          });
        });
      },
      handleCurrentChange(page) {
        this.query.current = page;
        this.selectAgriculturalList();
      },
      handleSizeChange(size) {
        this.query = {
          current: 1,
          size: size,
        };
        this.selectAgriculturalList();
      },
    },
  };
</script>

<style lang="less" scoped>
  /deep/.el-form--inline {
    display: flex;
    flex-wrap: wrap;
  }
</style>
