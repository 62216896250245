import service from '../request'
const baseUrl = window.globalUrl.HOME_API;
//新增机具
export const insertAgriculturalImplement = (params) => {
  return service({
    url: `${baseUrl}/trade-website/insertAgriculturalImplement`,
    method: 'post',
    data: params,
  })
}
// 查看我的农机
export const selectMyAgricultural = (params) => {
  return service({
    url: `${baseUrl}/trade-website/selectMyAgricultural`,
    method: 'get',
    params: params,
  })
}
// 运营公司查询农机管理

export const selectAgriculturalManager = (params) => {
  return service({
    url: `${baseUrl}/trade-website/selectAgriculturalManager`,
    method: 'get',
    params: params,
  })
}
// 查看详情

export const selectAgriculturalList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/selectAgriculturalList`,
    method: 'get',
    params: params,
  })
}
// 通过字典类型查找字典

export const carType = (params) => {
  return service({
    url: `${baseUrl}/admin/dict/type/${params}`,
    method: 'get',
    params: params,
  })
}
// 查询当前合作社是否绑定农机具或终端
export const selectCompanyAgriculrualMachinery = (params) => {
  return service({
    url: `${baseUrl}/trade-website/selectCompanyAgriculrualMachinery`,
    method: 'get',
    params: params,
  })
}
// 农机具管理-运营公司删除农机具

export const deleteAgriculturalImplement = (params) => {
  return service({
    url: `${baseUrl}/trade-website/deleteAgriculturalImplement`,
    method: 'delete',
    params: params,
  })
}

// 农机和机具的分配

export const insertCompanyAgricultural = (params) => {
  return service({
    url: `${baseUrl}/trade-website/insertCompanyAgricultural`,
    method: 'post',
    data: params,
  })
}
//农机和机具的取消分配

export const deleteCompanyAgricultural = (params) => {
  return service({
    url: `${baseUrl}/trade-website/deleteCompanyAgricultural`,
    method: 'delete',
    params: params,
  })
}
