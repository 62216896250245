<template>
  <el-dialog :visible.sync="dialogVisible" append-to-body width="70%" :before-close="handleClose">
    <div class="content">
      <div class="top">
        <div class="tabs">
          <div class="tab" v-for="(item,index) in tabsList" :key="index" @click="changeIndex(item.value)"
            :class="{active:activeIndex ===item.value}">
            {{item.name}}
          </div>
        </div>
        <div class="addItem">
          <el-button type="primary" @click="add(activeIndex)">新增</el-button>
        </div>

      </div>
      <div class="table">
        <table-page :data="tableData" :columns="columns" :currentPage="query.current" :pageSize="query.size"
          :total="total" :loading="loading" @sizeChange="handleSizeChange" @currentChange="handleCurrentChange"
          :border="true">
          <!-- <template slot="operation" slot-scope="scope">
            <el-button type="text" size="small" @click="detailsClick(activeIndex,scope.row)">详情</el-button>
            <el-button type="text" size="small" @click="Edit(activeIndex,scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="distribution(activeIndex,scope.row)">分配</el-button>
            <el-button type="text" size="small" @click="Edit(activeIndex,scope.row)">取消分配</el-button>
          </template> -->
        </table-page>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleClose">确 定</el-button>
    </span>
    <pz v-if="isPz" @closeDialog2="closeDialog2" ref="isAddEdit"></pz>
    <!-- <addEdit v-if="isAddEdit" @closeDialog2="closeDialog2" ref="isAddEdit"></addEdit> -->
  </el-dialog>

</template>

<script>
  import pz from './pz.vue'
  // import addEdit from './addEdit.vue'
  import { selectAgriculturalList } from '@/api/njgl'
  import TablePage from '@/components/table/table-page.vue'
  import { njpzNjColumn, jjpzColumn } from '../../tableComlumns.js'
  export default {
    data() {
      return {
        isPz: '',
        dialogVisible: true,
        activeIndex: 1,
        tableData: [],
        columns: njpzNjColumn,
        loading: false,
        total: 0,
        // isAddEdit: false,
        companyAuthId: '',
        tabsList: [
          {
            name: '农机',
            value: 1,
          },
          {
            name: '机具',
            value: 2,
          },
          // {
          //   name: '终端',
          //   value: 3,
          // },
          // {
          //   name: '拖拉机',
          //   value: 4,
          // }
        ],
        query: {
          current: 1,
          size: 10,
        },
      };
    },
    watch: {
      activeIndex(newVal) {
        switch (newVal) {
          case 1:
            this.columns = njpzNjColumn;
            break;
          case 2:
            this.columns = jjpzColumn;
            break;
          // case 3:
          //   this.columns = zdDetailColumn
          //   break;
          // case 4:
          //   // 可以在这里添加处理逻辑，或者直接省略这两个case
          //   break;
          default:
            this.columns = []; // 或者根据需要设置默认值
            console.warn(`Unexpected activeIndex: ${newVal}`);
            break;
        }
      }
    },

    components: {
      TablePage,
      pz
      // addEdit
    },
    methods: {
      handleClose() {
        this.$emit("closeDialog", false);
        this.getList()
      },
      // 详情弹窗的内容
      async init(companyAuthId) {
        this.companyAuthId = companyAuthId
        this.getList()
      },

      // 列表
      async getList() {
        let params = {
          companyAuthId: this.companyAuthId,
          status: this.activeIndex,
          ...this.query,
          isAllocation: '1',
        }
        let res = await selectAgriculturalList(params)
        if (res.code == 0) {
          this.tableData = res.data.records
          this.total = Number(res.data.total)
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            // duration: 2000,
          })
        }
      },

      //切换选项
      changeIndex(index) {
        this.activeIndex = index
        // console.log(index)
        this.query.current = 1
        this.query.size = 10
        this.getList()
      },
      //新增
      add() {
        this.isPz = true;
        this.$nextTick(() => {
          this.$refs.isAddEdit.initData(this.activeIndex, this.companyAuthId);
        })
      },
      //新增
      // add(activeIndex) {
      //   this.isAddEdit = true;
      //   console.log(activeIndex, '查看activeIndex')
      //   this.$nextTick(() => {
      //     this.$refs.isAddEdit.initData2(activeIndex, this.companyAuthId);
      //   })
      // },
      // distribution(activeIndex, obj) {

      // },
      // // 编辑
      // Edit(activeIndex, obj) {
      //   console.log(obj, '查看Item')
      //   this.isAddEdit = true;
      //   let type = false
      //   this.$nextTick(() => {
      //     this.$refs.isAddEdit.EditData(activeIndex, this.companyAuthId, obj, type);
      //   })

      // },
      // //详情
      // detailsClick(activeIndex, obj) {
      //   this.isAddEdit = true;
      //   let type = true
      //   this.$nextTick(() => {
      //     this.$refs.isAddEdit.EditData(activeIndex, this.companyAuthId, obj, type);
      //   })
      // },
      // 关闭弹框
      // closeDialog2() {
      //   this.isAddEdit = false;
      //   this.getList()
      // },
      closeDialog2() {
        this.isPz = false;
        this.getList()
      },
      handleCurrentChange(page) {
        this.query.current = page;
        this.getList();
      },
      handleSizeChange(size) {
        this.query = {
          current: 1,
          size: size,
        };
        this.getList();
      },
    }
  };
</script>

<style lang="less" scoped>
  /deep/.el-dialog__header {
    background: white !important
  }

  .content {
    width: 100%;

    .top {
      width: 100%;
      display: flex;

      .tabs {
        width: 85%;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;

        .tab {
          padding: 10px 25px;
          border: 1px solid #ccc;
          border-bottom: none;
          background-color: #e0ecef;
          cursor: pointer;
          margin-right: 2px;
        }

        .tab.active {
          background-color: #5da3a6;
          color: white;
        }

      }





    }
  }
</style>
